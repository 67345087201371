.banner__text {
  width: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-top: 30px; }
  @media (max-width: 700px) {
    .banner__text {
      display: none; } }

.banner__social {
  position: absolute;
  z-index: 100;
  right: 10%;
  bottom: 5%;
  display: flex;
  flex-direction: column; }
  .banner__social a {
    margin-bottom: 12px; }

.banner_swiper {
  position: relative; }
  .banner_swiper__slide img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    filter: brightness(0.5); }
  .banner_swiper_info {
    position: absolute;
    z-index: 100;
    top: 0%;
    left: 0%;
    width: 60%; }
    .banner_swiper_info h1 {
      margin-bottom: 30px; }
    .banner_swiper_info form {
      margin-top: 50px;
      display: flex;
      align-items: center; }
    .banner_swiper_info input {
      background: transparent;
      border: none;
      color: white;
      border-bottom: 1px solid white;
      margin-right: 50px; }
      .banner_swiper_info input::placeholder {
        color: white; }

.top_banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: color; }

@media (max-width: 1400px) {
  .banner_swiper_info {
    width: 100%; } }

@media (max-width: 1000px) {
  .banner_swiper_info {
    width: 100%;
    height: 60vh; }
    .banner_swiper_info form {
      position: absolute;
      bottom: 0;
      flex-direction: column;
      align-items: flex-start; }
      .banner_swiper_info form input {
        margin-bottom: 20px; }
  .banner .container {
    padding: 5% 15%; }
  .banner__title {
    padding-top: 100px; } }
