.regular_title {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal; }
  .regular_title_0 {
    font-size: 12px; }
  .regular_title_1 {
    font-size: 14px; }
  .regular_title_2 {
    font-size: 16px; }
  .regular_title_3 {
    font-size: 18px; }
  .regular_title_6 {
    font-size: 30px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_1 {
    font-size: 14px; }
  .bold_title_2 {
    font-size: 16px; }

.color_black {
  color: #000000; }

.color_white {
  color: #fff; }

.color_red {
  color: #CF1A20; }

.color_gray {
  color: #828282; }

.color_gray1 {
  color: #333333; }

.color_gray2 {
  color: #272C35; }

.title_1 {
  font-family: 'Gotham Pro Bold';
  font-style: normal;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 12%; }
  @media (max-width: 1000px) {
    .title_1 {
      font-size: 24px;
      line-height: 38px; } }

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 30px; }
  @media (max-width: 1000px) {
    .title {
      font-size: 32px;
      line-height: 38px; } }
