@charset "UTF-8";
.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  background: rgba(0, 20, 53, 0.45);
  padding: 2% 5%;
  z-index: 50; }
  .header__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%; }
  .header_nav {
    display: flex; }
    .header_nav__item {
      position: relative;
      cursor: pointer; }
      .header_nav__item .header_nav__link {
        padding-right: 20px; }
        .header_nav__item .header_nav__link::after {
          content: "ˇ";
          position: absolute;
          right: 0px;
          font-size: 30px; }
      @media (min-width: 1000px) {
        .header_nav__item:hover .header_nav__hidden {
          display: block; }
          .header_nav__item:hover .header_nav__hidden a {
            text-transform: lowercase; }
        .header_nav__item:hover .header_nav__link {
          background: rgba(217, 217, 217, 0.3);
          border-radius: 4px; }
          .header_nav__item:hover .header_nav__link::after {
            content: "ˆ"; } }
      .header_nav__item.active .header_nav__hidden {
        display: flex; }
        .header_nav__item.active .header_nav__hidden a {
          text-transform: lowercase;
          margin-left: 15px; }
      .header_nav__item.active .header_nav__link {
        background: rgba(217, 217, 217, 0.3);
        border-radius: 4px; }
        .header_nav__item.active .header_nav__link::after {
          content: "ˆ"; }
    .header_nav__hidden {
      position: absolute;
      background: #315999;
      padding: 25px 15px;
      display: none;
      width: fit-content; }
      .header_nav__hidden a {
        font-size: 14px;
        color: #ffffff;
        white-space: nowrap;
        margin-bottom: 15px; }
    .header_nav__link {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #ffffff;
      padding: 5px 10px; }
      .header_nav__link:hover {
        background: rgba(217, 217, 217, 0.3);
        border-radius: 4px; }
  .header_address {
    display: flex;
    font-style: normal;
    color: #ffffff;
    margin: 0; }
    .header_address a {
      color: #ffffff; }
    .header_address__left {
      margin-right: 15px; }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 55px;
    height: 55px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .header_line {
    transition: all 0.2s;
    position: absolute;
    width: 31px;
    height: 3px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #ffffff; }
    .header_line_1 {
      top: 16px;
      left: 20px; }
    .header_line_2 {
      top: 24px;
      left: 20px; }
    .header_line_3 {
      top: 32px;
      left: 20px; }
  .header.active {
    background: #315999; }
    .header.active .header_line_1 {
      top: 24px;
      left: 20px;
      transform: rotate(45deg); }
    .header.active .header_line_2 {
      left: -100px; }
    .header.active .header_line_3 {
      top: 24px;
      left: 20px;
      transform: rotate(-45deg); }
    .header.active .header_nav {
      opacity: 1;
      left: 0;
      z-index: 3; }

.language-select {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif; }

.language-select select {
  padding: 8px;
  width: 60px;
  border: 1px solid white;
  border-radius: 4px;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer; }
  .language-select select option {
    color: black; }

@media (max-width: 1000px) {
  #language-select {
    display: none; } }

#language-select-mob {
  display: none;
  padding-left: 10px; }
  @media (max-width: 1000px) {
    #language-select-mob {
      display: block; } }

@media (max-width: 1000px) {
  .header {
    height: 100px; }
    .header_hamburger {
      display: flex; }
    .header_nav {
      position: fixed;
      opacity: 0;
      top: 100px;
      left: -400px;
      background: #315999;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: calc(100vh - 100px);
      transition: all 0.2s;
      padding: 20px; }
      .header_nav a,
      .header_nav .header_nav__item {
        margin-bottom: 15px; }
      .header_nav__hidden {
        position: static;
        flex-direction: column;
        padding: 5px 10px; }
        .header_nav__hidden a {
          margin-bottom: 0; }
    .header_address {
      display: none;
      flex-direction: column; } }
