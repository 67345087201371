@media (max-width: 1000px) {
  .blog .blog__item {
    width: 48%; } }

@media (max-width: 600px) {
  .blog .blog__item {
    width: 100%; } }

.blog__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.blog__item {
  width: 30%;
  margin-bottom: 50px; }
  .blog__item img {
    width: 100%;
    height: 55%; }
    @media (max-width: 600px) {
      .blog__item img {
        height: auto; } }

.blog__title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  margin-top: 20px; }

.blog__date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #9B9B9B;
  margin-top: 15px; }

.blog_text_wrapper {
  height: 35%; }
  @media (max-width: 1000px) {
    .blog_text_wrapper {
      height: auto; } }

.blog__text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 20px 0 40px 0; }

.blog .btn_blue {
  display: block;
  width: fit-content; }
