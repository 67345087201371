.services__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.services_item {
  position: relative; }
  .services_item img {
    position: absolute;
    z-index: -1; }
  .services_item__title {
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
    color: #315999;
    padding-left: 30px; }
    .services_item__title span {
      font-size: 14px; }
  .services_item__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding-left: 30px;
    width: 300px; }

.services__bottom {
  margin-top: 90px; }
  .services__bottom .card {
    width: 32%;
    height: auto; }

.about {
  display: flex; }
  .about__left {
    background: #315999; }
    .about__left h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 25px; }
    .about__left a {
      color: white;
      border-bottom: 1px solid white; }
  .about__text {
    margin-bottom: 35px; }
  .about > div {
    width: 50%; }
    .about > div img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.partners {
  background: #FAFAFA; }
  .partners_wrapper {
    display: flex;
    flex-wrap: wrap; }
    .partners_wrapper img {
      width: 25%;
      max-height: 100px;
      height: 100%;
      border-collapse: collapse;
      border: 1px solid lightgray;
      object-fit: contain; }
      @media (max-width: 500px) {
        .partners_wrapper img {
          max-height: 50px; } }
    .partners_wrapper .cover {
      object-fit: cover; }

@media (max-width: 1000px) {
  .services__top {
    flex-direction: column;
    padding-top: 20px; }
  .services__bottom {
    margin-top: 0px; }
  .services_item {
    width: 100%;
    margin-bottom: 50px; }
    .services_item__text {
      width: 100%; }
  .about {
    flex-direction: column; }
    .about > div {
      width: 100%; }
  .partners_wrapper img {
    width: 50%; } }
