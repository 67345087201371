.card {
  width: 49%;
  padding: 15% 30px 30px 30px;
  color: white;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: color; }
  @media (max-width: 700px) {
    .card {
      width: 100%; }
      .card__wrapper {
        flex-direction: column; } }
  .card__title {
    font-family: 'Gotham Pro Bold';
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 8px; }
  .card__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 38px; }
  .card__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
