.projects_swiper {
  padding-bottom: 90px; }
  .projects_swiper_pagination {
    display: flex;
    justify-content: center; }
  .projects_swiper_slide {
    position: relative;
    height: auto;
    min-height: 400px; }
    .projects_swiper_slide a {
      height: 100%;
      display: block; }
    .projects_swiper_slide img {
      width: 100%;
      max-height: 350px;
      height: 70%;
      display: block;
      object-fit: cover; }
    .projects_swiper_slide__bottom {
      width: 100%;
      height: 30%;
      background: #315999;
      padding: 20px 15px;
      color: white;
      font-style: normal; }
    .projects_swiper_slide__title {
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .projects_swiper_slide__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px; }
