.swiper-pagination-progressbar {
  width: 25%;
  height: 2px;
  background: #DCDCDC; }
  .swiper-pagination-progressbar-fill {
    background-color: #000000; }

.swiper-pagination-current,
.currentZero {
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 99px;
  text-align: center;
  color: white; }

.swiper-pagination-total,
.totalZero {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: white; }

.top_slider-pagination {
  bottom: 8%; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #000000; }

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  width: 56px;
  height: 56px;
  z-index: 1000; }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 48px;
    color: #FFFFFF; }

.projects_swiper-btn-next:after,
.projects_swiper-btn-prev:after {
  color: #315999; }

.top-slider-btn-prev {
  left: 2%; }

.top-slider-btn-next {
  right: 2%; }

.product-slider-btn-next:after,
.product-slider-btn-prev:after {
  color: black; }

.swiper-pagination-bullet {
  width: 74px;
  height: 5px;
  background: #D9D9D9;
  border-radius: 0; }
  .swiper-pagination-bullet-active {
    background: #365DA0 !important; }
  .swiper-pagination-bullet::after {
    border: none; }
