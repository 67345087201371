.footer {
  background: #FAFAFA;
  padding: 5%;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1000px) {
    .footer {
      flex-direction: column; }
      .footer__section {
        margin-bottom: 30px; }
      .footer iframe {
        width: 100%; } }
  .footer__section {
    display: flex;
    flex-direction: column; }
  .footer__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #315999;
    margin-bottom: 15px; }
  .footer__link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 6px; }
  .footer__logo {
    width: 10vw; }
